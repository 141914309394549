import React from 'react';
import './StyleSection.css';
import Slide from 'react-reveal/Slide';
import { FaMobileAlt, FaLaptopCode, FaCloud, FaServer } from 'react-icons/fa';


const HeroSection = () => {
    
        const services = [
          {
              image: 'weq.jpg',
              title: 'Digital Product',
              description: `You have an existing digital product or web-based software that needs to be redesigned.`,
              description1:`You have a complex platform or system that needs custom design and development work.`,
              description2:`You've built an internal business tool that you'd like to bring to market and sell to consumers.`
          },
          {
              image: 'wbsite.jpg',
              title: 'Website Development',
              description: `You have an existing marketing website that is outdated and needs to be redesigned`,
              description1: `You have an existing marketing website that isn't converting or meeting your business goals`,
              description2:`You need a new custom marketing website to promote your product or business`
          },
          {
              image: 'an.jpg',
              title: 'Mobile Development',
              description: `You have an existing mobile app that is outdated or unusable and needs to be redesigned`,
              description1: `You're bringing a new mobile app to market and need it to be professionally designed`,
              description2:`You have an existing web-based product or tool that may be more useful as a native mobile app`
          }
        ];


        const technologies = [
          {
              icon: <FaMobileAlt />,
              category: 'Mobile App Development',
              stack: ['Flutter', 'Swift', 'React Native']
          },
          {
              icon: <FaLaptopCode />,
              category: 'Website Development',
              stack: ['React.JS', 'Angular.JS', 'Vue.JS']
          },
          {
              icon: <FaCloud />,
              category: 'Cloud Development',
              stack: ['AWS', 'Azure', 'Google Cloud']
          },
          {
              icon: <FaServer />,
              category: 'Backend Technologies',
              stack: ['Node.js', 'Python', 'Java']
          }
        ];
      

    return (
        <>
              <div>
                <div className='our_se' id='our-serv'>
                    <h2 className='ours'>Our Services</h2>
                </div>
                
                <Slide right>
                  <div className="services-section">
                    <div className="services-grid">
                      {services.map((service, index) => (
                        <div key={index} className="service-box">
                            <img src={service.image} alt={service.title} className="service-image" />
                            <h3 className="service-title">{service.title}</h3>
                            <p className="service-description">{service.description}</p>
                            <p className="service-description">{service.description1}</p>
                            <p className="service-description">{service.description2}</p>
                        </div>
                      ))}
                      </div>
                  </div>


                    <div className='our_se' id='about'>
                        <h2 className='ours'>About us</h2>
                    </div>

                </Slide>


                <Slide right>
                    <p className='about'>
                    Our goal is to streamline processes for our customers and help them achieve optimized organizational performance. From web and mobile application development, to e-commerce and digital marketing, we offer a wide range of services to meet the unique needs of any business. We also specialize in data science and artificial intelligence, utilizing leading technologies such as machine learning and natural language processing to drive growth and success for our clients. At Qutaas Technologies, we are dedicated to providing exceptional software solutions and dedicated customer support.
                    </p>
                </Slide>
                
                 
                 <Slide top>

                    <div className='our_se' id='tech'>
                        <h2 className='ours'>Our Technologies</h2>
                    </div>
                    
                    <div className='logos'>
                        <div className='logos-slide' >
                        <img src='React.png' alt="t1" className='tech'/>
                        <img src='python.png' alt="t1" className='tech' />
                        <img src='PHP.png' alt="t1" className='tech'/>
                        <img src='node_js.png' alt="t1" className='tech'/>
                        <img src='vuejs.png' alt="t1" className='tech'/>
                        <img src='wordpress.png' alt="t1" className='tech'/>
                        <img src='Laravel.png' alt="t1" className='tech'/>
                        <img src='js.png' alt="t1" className='tech'/>
                        <img src='java.png' alt="t1" className='tech'/>
                        <img src='ionic.png' alt="t1" className='tech'/>
                        <img src='flutter.png' alt="t1" className='tech'/>
                        <img src='React.png' alt="t1" className='tech'/>
                        <img src='python.png' alt="t1" className='tech' />
                        <img src='PHP.png' alt="t1" className='tech'/>
                        <img src='node_js.png' alt="t1" className='tech'/>
                        <img src='vuejs.png' alt="t1" className='tech'/>
                        <img src='wordpress.png' alt="t1" className='tech'/>
                        <img src='Laravel.png' alt="t1" className='tech'/>
                        <img src='js.png' alt="t1" className='tech'/>
                        <img src='java.png' alt="t1" className='tech'/>
                        <img src='ionic.png' alt="t1" className='tech'/>
                        <img src='flutter.png' alt="t1" className='tech'/>
                        </div>
                    </div>
                     
                    <div className="technologies-section">
            
                    <div className="technologies-grid">
                        {technologies.map((tech, index) => (
                            <div key={index} className="technology-box">
                                <div className="technology-icon">{tech.icon}</div>
                                <div className="technology-hover">
                                    <h3 className="technology-category">{tech.category}</h3>
                                    <ul className="technology-stack">
                                        {tech.stack.map((techItem, idx) => (
                                            <li key={idx}>{techItem}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                          ))}
                       </div>
                    </div>
      

                 </Slide>



                 <Slide bottom>

                        <div className='our_se' id='contact' style={{ marginTop:-50}}>
                            <h2 className='ours'>Contact us</h2>
                         </div>
                         
                        <footer className="footer-container">
                            <div className="footer-left">
                                <img src="qttt.png" alt="Company Logo" />
                            </div>
                            <div className="footer-center">
                                <h2>Quick links</h2>
                                <a href='#Home'>Home</a>
                                <a href='#Home'>About</a>
                                <a href='#Home'>Service</a>
                                <a href='#Home'>Technologies</a>
                            </div>
                            <div className="footer-center">
                                <h2>Contact info</h2>
                                <p>+91 8667274160</p>
                                <p>Perundurai - Erode </p>
                                <p>connect@qutaas.com</p>
                                <p>arunkulandaivel@outlook.com</p>
                            </div>
                            <div className="footer-right">
                                <h2>Socials</h2>
                                <a href="https://wa.me/918667274160">WhatsApp</a>
                                <a href="https://instagram.com">Instagram</a>
                                <a href="https://facebook.com">FaceBook</a>
                                <a href="https://linkedin.com">Linkedin</a>
                                <a href="https://twitter.com">X</a>
                            </div>
                        </footer>
                 </Slide>
            </div>

            <a href="https://wa.me/918667274160" className="get-in-touch-button" target="_blank" rel="noopener noreferrer">
                GET IN TOUCH
            </a>
            {/* <ScrollButton /> */}
            
        </>
    );
}

export default HeroSection;
