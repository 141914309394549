import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HeroSection from './components/HeroSection';
import Hero from './components/Hero';


function App() {
  return (
    <>
    <Router>
        <Routes>
          <Route path="/" element={<>

            <Hero/>
            <HeroSection />
            
          </>} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
