import { FaBars, FaTimes } from 'react-icons/fa';
import React, { useState ,useEffect} from 'react';
import './HeroStyle.css';

const Hero = () => {
    const [showMenu, setShowMenu] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const handleMouseEnter = () => {
        setShowDropdown(true);
    };

    const handleMouseLeave = () => {
        setShowDropdown(false);
    };


    const texts = [
        'Innovative. Reliable. Professional.',
        'Creative. Cutting-Edge. Efficient.',
        'Dynamic. Client-Focused. Affordable.',
        'Custom. Modern. Scalable.'
    ];
    const [currentText, setCurrentText] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentText((prevText) => (prevText + 1) % texts.length);
        }, 3000);

        return () => clearInterval(interval);
    }, [texts.length]);

    return (
        <>
            <div className='hero-section'>
                <nav className="navbar">
                    <div className="navbar-container">
                        <div className="navbar-logo">
                            <img 
                            src='main.png' alt='Logo' className='imgl' />
                        </div>
                        <div className="menu-icon" onClick={toggleMenu}>
                            {showMenu ? <FaTimes /> : <FaBars />}
                        </div>
                        <ul className={showMenu ? 'nav-menu active' : 'nav-menu'}>
                            <li className="nav-item" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                <a href="#our-serv" className="nav-link">Our Service</a>
                                <div className={`dropdown ${showDropdown ? 'show' : ''}`}>
                                    <a href="#our-serv" className="dropdown-link">Website Development</a>
                                    <a href="#our-serv" className="dropdown-link">Mobile Development</a>
                                    <a href="#our-serv" className="dropdown-link">Digital Marketing</a>
                                </div>
                            </li>
                            <li className="nav-item">
                                <a href="#about" className="nav-link">About</a>
                            </li>
                            <li className="nav-item">
                                <a href="#tech" className="nav-link">Technologies</a>
                            </li>
                            <li className="nav-item" id='navv' style={{ backgroundColor: 'white', padding: 10, borderRadius: 10, marginTop: 15, maxWidth: 70 }}>
                                <a href="#contact" className="nav-link" id='nav-i' style={{ color: '#CF0A0A', fontWeight: 500 }}> Contact </a>
                            </li>
                        </ul>
                    </div>
                </nav>
                <div className="hero-content">
                    <p className='dyn'>{texts[currentText]}</p>
                </div>
            </div>
        </>
    );
};

export default Hero;
